import React, { useEffect, useState } from "react";
import { useApiGet } from "../../../api/apiCall";
import { Link } from "react-router-dom";
import {
	// getAllApplicationTypesUnpaginatedUrl,
	getRecentNoticessUrl
} from "../../../api/urls";
import {
	// ApplicationTabs,
	GlobalMenu,
	MessageBox
} from "../../../ui_elements";
import {
	// Jupeb,
	// Sandwich,
	// Undergraduate ,
	LandingModal
} from "./containers";
import DOMPurify from "dompurify";
// import Hero from "../../../assets/images/hero_landing_image.png";
import HeroBig from "../../../assets/images/hero_landing_image_desktop.png";

import styles from "./styles.module.css";
import { ArrowUp } from "../../../assets/svgs";
const date = new Date();

const Home = () => {
	const [message, setMessage] = useState(true);
	const [modal, setModal] = useState(false);
	const handleCurrentLink = (linkObject) => {
		if (linkObject?.applicationMode === "1") {
			return {
				pathname: `/generate_jamb_students_invoice`,
				state: { application: linkObject }
			};
		} else if (linkObject?.applicationMode === "2") {
			return {
				pathname: `/generate_jamb_students_invoice`,
				state: { application: linkObject }
			};
		} else if (linkObject?.applicationMode === "3") {
			return {
				pathname: `/generate_other_students_invoice`,
				state: { application: linkObject }
			};
		} else {
			console.log("Not a valid application mode");
		}
	};

	const applicationForms = {
		nd: { applicationMode: "1", id: "1", name: "ND APPLICATION " },
		hnd: { applicationMode: "2", id: "2", name: "HND APPLICATION" },
		partTime: {
			applicationMode: "3",
			id: "4",
			name: "ND PART-TIME APPLICATION"
		}
	};
	const { data, isFetched } = useApiGet(getRecentNoticessUrl(), {
		keepPreviousData: true
	});

	useEffect(() => {
		if (isFetched) {
			setModal(true);
		}
	}, [isFetched]);

	const handleScroll = () => {
		window.scrollTo(0, document.body.scrollHeight);
	};

	return (
		<>
			{!data?.data || data?.data.length === 0 ? null : (
				<LandingModal
					data={data?.data}
					isOpen={modal}
					closeModal={() => setModal(false)}
				/>
			)}
			{!data?.data ||
			data?.data?.length === 0 ||
			message === false ? null : (
				<MessageBox
					openModal={() => setModal(true)}
					closeMessage={setMessage}
					title={data?.data[0]?.title}
					message={
						<div
							className={`d-flex align-items-baseline ${styles.message_body}`}
						>
							<div
								dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(
										data?.data[0]?.description.slice(0, 70)
									)
								}}
							/>
							<span>....</span>
						</div>
					}
				/>
			)}
			<GlobalMenu isLanding={"unAuthenticated"} />
			<main className={`${styles.container}`}>
				<div className={styles.firstCover}>
					<div className={styles.sectionsContainer}>
						<section className="d-flex justify-content-center w-100 justify-content-md-between align-items-center">
							<div>
								{/* {currentApplication ? (
							<div className={styles.notice}>
								<span>Notice</span>
								{`${currentApplication.name} is now open!`}
							</div>
						) : (
							<></>
						)} */}
								<h1>
									Welcome to Enugu State College of Education
									Portal
									<br />
								</h1>
								<p className="mb-4">
									Embark on a journey through the virtual
									doors of our Portal - Your Gateway to
									Knowledge, Excellence, and Boundless
									Opportunities!
								</p>
								<button
									onClick={handleScroll}
									className={styles.btn}
								>
									Learn more
								</button>
							</div>
							<div className={styles.landing_sect}>
								{/* <HomeBubbleIcon />
								<HomeBookIcon />
								<HomeShapeIcon />
								<WelecomeCircle
									className={styles.welcome_circle}
								/> */}
								<div className={styles.desktop_landing_img}>
									<img src={HeroBig} alt="landing_img" />
								</div>
								<img
									src={HeroBig}
									alt="landing_img"
									className="mobile_landing_img"
								/>
							</div>
						</section>
					</div>
				</div>
			</main>
			<div className={styles.application_section_container}>
				<div className={`mb-2 ${styles.application_section_header}`}>
					<h2 className="pb-1 mb-1">Our Programmes</h2>
					<p>
						Explore a World of Learning Possibilities: Discover Our
						Diverse Academic Pathways
					</p>
				</div>
				<div className={styles.application_section_container_cards}>
					<div className={styles.application_card}>
						<h3>HND Programme</h3>
						<p className="text-left mt-1">
							Please select a link applicable to your needs from
							the categories below
						</p>
						<div className={`${styles.cardLinks} `}>
							<Link
								to={() =>
									handleCurrentLink(applicationForms.hnd)
								}
							>
								Generate Invoice
							</Link>
							<Link to="/diploma_login">Application Form</Link>
						</div>
					</div>
					<div className={styles.application_card}>
						<h3>ND Programme</h3>
						<p className="text-left mt-1">
							Please select a link applicable to your needs from
							the categories below
						</p>
						<div className={`${styles.cardLinks} `}>
							<Link
								to={() =>
									handleCurrentLink(applicationForms.nd)
								}
							>
								Generate Invoice
							</Link>
							<Link to="/diploma_login">Application Form</Link>
						</div>
					</div>
				</div>
			</div>
			<footer
				className={`d-flex align-items-center justify-content-center mt-5 ${styles.home_footer}`}
			>
				<p>
					{`Copyright©️ ${date.getFullYear()} All rights reserved by Enugu State College of Education (Technical). Powered by Tenece`}
				</p>
				<div
					className={`${styles.fab} d-flex align-items-center justify-content-center`}
					onClick={() =>
						window.scrollTo({
							top: 0,
							behavior: "smooth"
						})
					}
				>
					<ArrowUp />
				</div>
			</footer>
		</>
	);
};

export default Home;

// const ApplicationCard = ({ handleCurrentLink, applicationForm }) => {
// 	return (
// 		<div className={styles.application_card}>
// 			<h3>{applicationForm?.name?.toLowerCase()} Program</h3>
// 			<p className="text-left mt-1">
// 				Please select a link applicable to your needs from the
// 				categories below
// 			</p>
// 			<div className={`${styles.cardLinks} `}>
// 				<Link to={() => handleCurrentLink(applicationForm.DIPLOMA)}>
// 					Generate Invoice
// 				</Link>
// 				<Link to="/diploma_login">Application Form</Link>
// 			</div>
// 		</div>
// 	);
// };
